<template>
  <svg
    :viewBox="icon.viewBox ? `${icon.viewBox}` : rawBox || undefined"
    :width="width || viewBoxWidth || rawWidth"
    :height="height || viewBoxHeight || rawHeight"
  >
    <use
      v-if="icon.viewBox"
      :xlink:href="`#${icon.id}`"
    />
    <g
      v-else
      v-html="rawIcon"
      fill="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgSpriteIcon',
  props: {
    icon: {
      type: [Object, String],
      default: () => ({}),
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      rawIcon: undefined,
      rawHeight: undefined,
      rawWidth: undefined,
      rawBox: undefined,
    }
  },
  computed: {
    viewBoxWidth() {
      return this.icon.viewBox?.split(' ')[2] || this.rawWidth || ''
    },
    viewBoxHeight() {
      return this.icon.viewBox?.split(' ')[3] || this.rawHeight || ''
    },
  },
  created() {
    if (this.icon.viewBox) return
    const url = decodeURIComponent(this.icon)

    if (url.startsWith('data:')) {
      const icon = url.replace('data:image/svg+xml,', '').replace('data:image/svg+xml;base64,', '')
      return this.processIcon(url.includes('base64') ? atob(icon) : icon)
    }
    fetch(this.icon)
      .then(response => response.text())
      .then(data => {
        if (!data) return
        this.processIcon(data)
      })
  },
  methods: {
    processIcon(icon) {
      this.rawBox = icon.match(/viewBox=['"]([^'"]*)['"]/)?.[1] || undefined
      const boxSizes = this.rawBox?.split(' ') || []
      this.rawWidth = boxSizes[2] || icon.match(/width=['"]([^'"]*)['"]/)?.[1] || undefined
      this.rawHeight = boxSizes[3] || icon.match(/height=['"]([^'"]*)['"]/)?.[1] || this.rawWidth || undefined
      this.rawIcon = icon.match(/<svg[\s\S]*?>([\s\S]*?)<\/svg>/)?.[1] || undefined
      this.rawBox = this.rawBox || `0 0 ${this.rawWidth || '0'} ${this.rawHeight || 0}`
    }
  }
}
</script>
